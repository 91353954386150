<template>
  <div class="earning">
    <div class="container">
      <div class="position-relative">
        <h1>{{$t('Earning')}}</h1>
        <!-- <p class="expectedEarning">Expected earnings: $28</p> -->
      </div>
      <!-- earning-summary -->
      <earning-summary />
      
      <div class="filtersOption">
        <div class="filtersOption__paymentOptions">
          <p>{{$t('Withdraw')}}:</p>
          <ul>
            <li>
              <a href="javascript:void(0);" v-b-modal.bankTransfer @click="notificationState()"
                >{{$t('Bank Tranfer')}}</a
              >
            </li>
          </ul>
        </div>
        <div class="filtersOption__showsFilters">
          <billing-filters :filter-types="filterTypes" @filter="filters" />
        </div>
      </div>
      <!-- billing card -->

      <div v-if="type == 'transactions'">
        <transactions :filter-date="this.filterDate"></transactions>
      </div>
      <div v-if="type == 'withdraws'">
        <withdraws :filter-date="this.filterDate"></withdraws>
      </div>

      <!-- modal1 -->

      <b-modal content-class="accountModal " id="paypalAccount">
        <div class="title">{{$t('Configurure Your Paypal Account')}}</div>
        <p>
          {{$t('We will use this PayPal account to send you money when you initiate a
          withdrawal.')}}
        </p>
        <b-form>
          <base-input
            placeholder="Email Address"
            type="email"
            rules="required"
            name="Email Address"
          />
          <base-input
            placeholder="Repeat Email Adress"
            type="email"
            rules="required"
            name="Repeat Email Adress"
          />
        </b-form>
        <p class="subTitle">
          {{$t('We will not be able to recover funds send to the wrong adress, please
          make sure the information you enter is correct.')}}
        </p>
        <base-checkbox-group
          name="agreement"
          :options="agreement"
          rules="required"
        ></base-checkbox-group>
        <button type="submit" class="btn btn-primary full">
          {{$t('Connect my Paypal Account')}}
        </button>
      </b-modal>

      <!-- modal2 -->

      <b-modal
        content-class="accountModal"
        id="bankTransfer"
        ref="bankTransfer"
        no-close-on-backdrop
        centered
        v-if="showBankTransferModal"
      >
        <bank-transfer
          :title="$t('Bank Transfer')"
          :sub-title="$t('We will use Bank Tranfer to send you money when you initiate a withdrawal.')"
          :note="$t('We will not be able to recover funds send to the wrong IBAN Acount, please make sure the information you enter is correct.')"
          @submitted="closeModal"
        ></bank-transfer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    data: [String, Number, Object],
  },
  data() {
    return {
      selected: "",
      agreement: [{ text: this.$i18n.t('I understand and agree.') }],
      type: "transactions",
      filterTypes: [
        { value: "withdraws", text: this.$i18n.t('Withdraws') },
        { value: "transactions", text: this.$i18n.t('Transactions') },
      ],
      filterDate: {
        startDate: null,
        endDate: null,
      },
      showBankTransferModal: true,
    };
  },
  components: {
    EarningSummary: () =>
      import(
        /* webpackChunkName: "EarningSummary" */ "@/components/user/earnings/EarningSummary.vue"
      ),
    BillingFilters: () =>
      import(
        /* webpackChunkName: "BillingFilters" */ "@/components/common/billing/BillingFilters.vue"
      ),
    Transactions: () =>
      import(
        /* webpackChunkName: "Trasactions" */ "@/components/common/billing/Transactions.vue"
      ),
    Withdraws: () =>
      import(
        /* webpackChunkName: "Withdraws" */ "@/components/user/earnings/Withdraws.vue"
      ),
    BankTransfer: () =>
      import(
        /* webpackChunkName: "Banktransfer" */ "@/components/user/earnings/BankTransfer.vue"
      ),
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    this.getBalance();
    if (this.user.type == 0) {
      this.showBankTransferModal = this.user.balance < 100 ? false : true;
    }
  },
  methods: {
    ...mapActions(["getBalance"]),
    closeModal() {
      this.$refs["bankTransfer"].hide();
    },
    filters(data) {
      this.type = data.type;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.hasMore = true;
      this.infiniteId += 1;
      this.page = 1;
      this.isFirst = true;
      this.filterDate.startDate = data.startDate;
      this.filterDate.endDate = data.endDate;
    },
    notificationState() {
      if (!this.showBankTransferModal) {
        this.notify("error", this.$i18n.t('Withdrawal requests permitted when amount in your wallet is higher then 100$'));
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.earning {
  h1 {
    font-weight: 700;
    line-height: 1;
  }
  .expectedEarning {
    position: absolute;
    right: 0;
    top: 25px;
    font-size: rem(18px);
    font-weight: 700;
    color: var(--textPrimary);
    margin: 0;
    @media screen and (max-width: 991px) {
      top: 20px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
</style>
